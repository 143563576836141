<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Calendar component
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Restricted Access",
    meta: [{ name: "description", content: "Restricted Access" }],
  },
  data() {
    return {
      title: "Restricted Access",
      items: [
        {
          text: "Restricted Access",
        },
        {
          text: "Restricted Access",
          active: true,
        },
      ],
    };
  },
  methods: {
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            Restricted Access
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
